import React from "react";
import './styles.css';
import SyncLoader from "react-spinners/SyncLoader";

export default function Loading({ loading }) {
  return (
    <>
      {loading && (
        <div id="content-loading">
          <SyncLoader
            size={10}
            color={"#FFBC42"}
            loading={loading}
          />
        </div>
      )}
    </>
  );
}