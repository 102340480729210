import styled from "styled-components";

export const ManageLinkzProfileContent = styled.div`
  width: 100vw;
  height: 100%;

  #container {
    width: 100%;
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
  }

  #mlp-links {
    margin-bottom: 20px;
  }

  #edit-name-actions{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0 0 0 5px;
    width: 80px;
  }

  .box-shadow{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  }

  .select-box{
    margin-right: 5px;
  }

  #mlp-save-name {
  background: rgb(78,83,245);
  color: #fff;
  width: 30px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
#mlp-cancel-save-name {
  background: rgb(255,81,81);
  color: #fff;
  width: 30px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

  #welcome {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;

    #mlp-edit-username {
      background: #536ef5;
      color: #fff;
      width: 30px;
      height: 30px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .profile-avatar-edit-btn {
    height: 100px;
    width: 100px;
    background: #ff000026;
    opacity: 0;
    position: absolute;
    top: 6.5rem;
    left: 46.5vw;
  }

  .width-100{
    width: 100%;
  }

  .top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 30%;
    
    #menu{
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .page-name{
    font-size: 25px;
    font-weight: bold;
    margin: 20px 0;
  }

  .icon{
      /* font-size: 20px; */
      margin-right: 10px;
      cursor: pointer;
    }
`;

export const MenuLinkz = styled.div`
  width: 100vw;
  height: 100%;
  background: #DEDEDE;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 20px;
  user-select: none;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 20px 10vw;
  }

  @media screen and (min-width: 1025px) and (max-width: 1920px) {
      padding: 10px 20vw;
  }

  @media screen and (min-width: 1921px) {
    padding: 30px 30vw;
  }

  .top{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 30%;
    
    #close{
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .middle{
    width: 100%;
    height: 60%;

    .menu-item {
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      cursor: pointer;
      font-size: 20px;
      margin-top: 5px;
    }

    #logout{
      margin-top: 30px
    }

    .menu-icon{
      font-size: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  
    #icon-logout {
      margin-left: 5px;
    }

  }

  .bottom{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const Container = styled.div`

width: 100%;
  /* height: 100vh; */
  height: fit-content;
  /* padding: 20px; */

#lzm-not-found-container{
  /* width: calc(100vw - 40px); */
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
}


#not-found-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#lzm-not-found-container img {
  width: 220px;
}

`;