import React, { useEffect, useState } from "react";
import noPicture from "../../assets/img/no-picture.svg";
import { MenuLinkz, ManageLinkzProfileContent } from "./styles.js";
import {
  firebaseAuth,
  firebaseFirestore,
  firebaseStorage,
} from "../../providers/firebase.provider";
import Loading from "../../components/loading/loading";
import { Avatar, Button, Icon, Input } from "rsuite";
import LinkzList from "../../components/linkz-list";
import LinkzFooter from "../../components/linkz-footer";
import { logout } from "../../services/auth.service";

import * as toast from "../../providers/toast.provider";
import { useHistory } from "react-router-dom";
import * as environment from "../../environments/env";
import useAnalyticsEventTracker from "../../utils/ga-event-tracker";

export default function ManageLinkzProfile() {
  const history = useHistory();
  const usersDB = firebaseFirestore.collection("users");
  const gaEventTracker = useAnalyticsEventTracker("Manage-linkz-profile");

  const itemsLimit = 50;

  const [modalOpened, setModalOpened] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    username: "",
    email: "",
    photo: "",
    links: [],
  });

  const [userCompleteName, setUserCompleteName] = useState("");
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const [showEditNameInput, setIsShowEditNameInput] = useState(false);
  const [loading, setLoading] = useState(true);
  const [photo, setPhoto] = useState("");
  const [links, setLinks] = useState([]);
  const [newLink, setNewLink] = useState({
    description: "",
    url: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  // const [setUrlProtocol] = useState("https://");

  function add() {
    if (!newLink.description || newLink.description === "") {
      return toast.error("A descrição do link não pode ser vazia");
    }
    if (!newLink.url || newLink.url === "") {
      return toast.error("A URL do link não pode ser vazia");
    }

    if (
      !newLink.url.match(
        new RegExp(
          /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
        )
      )
    ) {
      return toast.error(
        "A URL que você está tentando salvar é inválida. Tente novamente"
      );
    }

    if (links.length >= itemsLimit && !isEdit) {
      return toast.error(
        `Você só pode adicionar no máximo ${itemsLimit} itens`
      );
    }

    const url =
      newLink.url.startsWith("http://") || newLink.url.startsWith("https://")
        ? `${newLink.url}`
        : `http://${newLink.url}`;
    newLink.url = url;

    let linksList = links;
    if (isEdit) {
      linksList.splice(editIndex, 1, newLink);
      setIsEdit(false);
      setEditIndex(null);
      setModalOpened(false);
    } else {
      linksList.push(newLink);
    }

    setNewLink({ description: "", url: "" });
    setModalOpened(false);

    gaEventTracker("add link");
    return save(linksList, isEdit ? "edit" : "save");
  }

  async function saveName() {
    if (!userCompleteName || userCompleteName === "") {
      return toast.error("Você precisa informar seu nome antes de salvar");
    }

    setLoading(true);
    setLoading(true);
    userData.name = userCompleteName;
    const data = userData;
    setUserData(data);
    await usersDB.doc(data.email).update(data);
    setLoading(false);
    setIsShowEditNameInput(false);
    return toast.success("Seu nome foi atualizado com sucesso!");
  }

  async function save(linksList, action) {
    if (!userData.name || userData.name === "") {
      return toast.error("Você precisa informar seu nome antes de salvar");
    }
    setLoading(true);
    const data = userData;
    linksList && linksList.length > 0
      ? (data.links = linksList)
      : (data.links = []);
    setUserData(data);

    if (
      action !== "save" &&
      !window.confirm(
        `Tem certeza que deseja ${action === "save"
          ? "salvar"
          : action === "edit"
            ? "editar"
            : "deletar"
        } as informações?`
      )
    ) {
      return setLoading(false);
    }

    await usersDB.doc(data.email).update(data);
    toast.success(
      `Informações ${action === "save"
        ? "salvas"
        : action === "edit"
          ? "editadas"
          : "deletadas"
      } com sucesso!`
    );

    gaEventTracker("salvou perfil");
    return setLoading(false);
  }

  function openProfile() {
    gaEventTracker("previsualizou perfil");
    history.push(`/${userData.username}`, { preview: true });
  }

  function copyLink() {
    navigator.clipboard.writeText(
      document.getElementById("profile-url").textContent
    );

    gaEventTracker("copiou link");
  }

  function loadUser(username) {
    setLoading(true);
    let foo = null;
    usersDB.where("username", "==", username).onSnapshot(async (data) => {
      data.forEach(async (child) => {
        let item = null;
        item = child.data();
        foo = item;
        const bar = !item.links ? [] : item.links;
        setUserCompleteName(item.name);
        setUserData({
          name: item.name,
          username: item.username,
          email: item.email,
          photo: item.photo || null,
          links: bar,
        });

        localStorage.setItem('@AUTH.user.userData', JSON.stringify({
          name: item.name,
          username: item.username,
          email: item.email,
          photo: item.photo || null,
          links: bar,
          subscription: item.subscription
        }))

        setPhoto(item.photo ? item.photo : noPicture);
        setLinks(bar);
        return setLoading(false);
      });

      if (foo) {
        return;
      }
    });
  }

  function deleteItem(index) {
    const values = [...links];
    values.splice(index, 1);
    gaEventTracker("deletou link");
    return save(values, "remove");
  }

  function editPhoto(event) {
    setLoading(true);
    const file = event.target.files[0];
    const timestamp = new Date().getTime();
    let fileName = null;

    switch (file.type) {
      case "image/jpeg":
        fileName = timestamp + ".jpeg";
        break;
      case "image/png":
        fileName = timestamp + ".png";
        break;
      case "image/jpg":
        fileName = timestamp + ".jpg";
        break;
      default:
        return toast.error(
          "Formato de imagem inválido. Formatos aceitos: JPG, JPEG e PNG"
        );
    }

    firebaseStorage
      .ref("profilePhotos/" + fileName)
      .put(file)
      .then(
        (data) => {
          firebaseStorage
            .ref("profilePhotos/" + fileName)
            .getDownloadURL()
            .then((data) => {
              firebaseFirestore
                .collection("users")
                .doc(userData.email)
                .update({ photo: data })
                .then((url) => {
                  toast.success("Imagem adicionada com sucesso!");

                  const data = userData;
                  data.photo = url;
                  setUserData(data);
                  setPhoto(userData.photo);
                });
            });
          gaEventTracker("editou foto");
          setLoading(false);
        },
        (error) => {
          console.error(error);
          setLoading(false);
        }
      );
  }

  function openCloseAddEditLinkModal(e, isEdit, index) {
    e.preventDefault();

    if (isEdit) {
      setNewLink({ ...links[index] });
      setEditIndex(index);
      setIsEdit(isEdit);

      gaEventTracker("editou link");
      return setModalOpened(!modalOpened);
    }

    setNewLink({ description: "", url: "" });
    setIsEdit(false);
    setEditIndex(null);

    gaEventTracker("cadastrou novo link");
    return setModalOpened(!modalOpened);
  }

  function checkAuth() {
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        return loadUser(localStorage.getItem("@AUTH.user.username"));
      } else {
        logout();
        return history.push("/login");
      }
    });
  }

  function cancelEditName() {
    setUserCompleteName(userData.name);
    setIsShowEditNameInput(false);
  }

  function openCloseMenu() {
    setIsMenuOpened(!isMenuOpened)
  }

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ManageLinkzProfileContent>
      <Loading loading={loading} />
      <div className="container">
        <div className="top">
          <div onClick={() => openCloseMenu()}>
            <Icon id="menu" icon="more" />
          </div>
        </div>
        {isMenuOpened && (
          <MenuLinkz>
            <div className="top">
              <div onClick={() => openCloseMenu()}>
                <Icon id="close" icon="close" />
              </div>
            </div>
            <div className="middle">
              <div className="menu-item" onClick={() => history.push('/dashboard', {userData})}><Icon className="menu-icon" icon="dashboard" /> Dashboard</div>
              <div className="menu-item" onClick={() => {
                openCloseMenu()
              }}><Icon className="menu-icon" icon="list" /> Meus links</div>
              <div className="menu-item"
                onClick={() => {
                  openProfile();
                }}><Icon className="menu-icon" icon="id-card" /> Perfil público</div>
              <div className="menu-item" id="logout" onClick={() => logout()}>
                Sair <Icon id="icon-logout" className="menu-icon" icon="sign-out" />
              </div>
            </div>
            <div className="bottom">Versão: 1.0.0</div>
          </MenuLinkz>
        )}
        <div id="mlp-content">
          <div id="welcome">
            {" "}
            Olá &nbsp; <b>@{userData.username}</b>{" "}
            {/* <Icon
              id="mlp-edit-username"
              icon="edit2"
              onClick={(e) => openCloseAddEditLinkModal(e)}
            /> */}
          </div>
          <div className="form">
            <div className="profile-avatar">
              <Avatar className="img-profile-avatar" circle src={photo} />

              <input
                className="profile-avatar-edit-btn"
                type="file"
                onChange={(event) => editPhoto(event)}
              />
            </div>

            <div className="flex justify-center">
              {showEditNameInput ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="form-fields p-floating-container width-100">
                    <Input
                      type="text"
                      value={userCompleteName}
                      onChange={(value) => setUserCompleteName(value)}
                      placeholder={"Qual é o seu nome?"}
                    />
                    <label>Qual é o seu nome?</label>
                  </div>
                  <div id="edit-name-actions">
                    <Icon
                      id="mlp-save-name"
                      icon="check"
                      onClick={() => saveName()}
                    />
                    <Icon
                      id="mlp-cancel-save-name"
                      icon="close"
                      onClick={() => cancelEditName()}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div id="welcome">
                    {userCompleteName ? (
                      <b>{userCompleteName}</b>
                    ) : (
                      "Clique para editar seu nome"
                    )}
                    <Icon
                      id="mlp-edit-username"
                      icon="edit2"
                      onClick={() => setIsShowEditNameInput(true)}
                    />
                  </div>
                </>
              )}
            </div>

            <div
              className="flex justify-center"
              style={{
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ userSelect: "none" }}>Link do seu perfil: </p>{" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href={`${environment.env.uiDomain}/${userData.username}`}
                    id="profile-url"
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    <i>
                      <u>{`${environment.env.uiDomain}/${userData.username}`}</u>
                    </i>
                  </a>
                  &nbsp;
                  <Icon
                    id="mlp-add-link"
                    icon="copy"
                    style={{ cursor: "pointer" }}
                    onClick={copyLink}
                  />
                </div>
              </div>
            </div>

            <div className="fields">
              <Button
                className="primary-btn box-shadow"
                block={true}
                onClick={() => {
                  openProfile();
                }}
              >
                Visualizar meu perfil
              </Button>

              {links.length < itemsLimit && (
                <div id="mlp-links-title">
                  <label>Meus links:</label>
                  <Icon
                    id="mlp-add-link"
                    icon="plus"
                    onClick={(e) => openCloseAddEditLinkModal(e)}
                  />
                </div>
              )}

              <div id="mlp-links">
                <LinkzList
                  links={links}
                  manage={true}
                  editLink={(e, index) =>
                    openCloseAddEditLinkModal(e, true, index)
                  }
                  deleteLink={(e, index) => deleteItem(index)}
                />
              </div>
            </div>
            <LinkzFooter />
          </div>

          {!!modalOpened && (
            <div className="modal">
              <div className="modal-card">
                {isEdit ? (
                  <div className="modal-title">Editar link</div>
                ) : (
                  <div className="modal-title">Adicionar novo link</div>
                )}
                <div className="modal-section">
                  <div className="form">
                    <div className="form-fields p-floating-container">
                      <Input
                        type="text"
                        value={newLink.description}
                        onChange={(value) =>
                          setNewLink({ ...newLink, description: value })
                        }
                        placeholder={"Descrição:"}
                      />
                      <label>Descrição:</label>
                    </div>
                    <div
                      className="form-fields p-floating-container"
                      style={{ flexDirection: "row" }}
                    >
                      {/* <select
                        className="select-box"
                        onChange={(e) => changeProtocol(e.target.value)}
                      >
                        <option selected value="https://">
                          https://
                        </option>
                        <option value="http://">http://</option>
                      </select> */}
                      <Input
                        type="text"
                        value={newLink.url}
                        onChange={(value) => {
                          setNewLink({ ...newLink, url: value });
                        }}
                        placeholder={"https://site.com.br"}
                      />
                      <label>URL:</label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="modal-buttons">
                    <Button
                      className="secondary-btn"
                      onClick={(e) => openCloseAddEditLinkModal(e)}
                    >
                      Fechar
                    </Button>
                    <Button className="primary-btn" onClick={() => add()}>
                      Salvar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*  <Button
          className='secondary-btn'
          block={true}
          onClick={() => { save() }}>Cancelar</Button>

        <Button
          className='ghost-btn'
          block={true}
          onClick={() => { save() }}>Ghost</Button> */}
        </div>
      </div>
    </ManageLinkzProfileContent>
  );
}
