import moment from "moment";
import { firebaseFirestore } from "../providers/firebase.provider";
import axios from "axios";
import { env } from "../environments/env";

moment.locale("pt-br");

const usersDB = firebaseFirestore.collection("users");

export async function addClick(email, linkIndex) {
  return new Promise((resolve) => {
    var resolveOnce = (doc) => {
      resolveOnce = () => void resolve(doc);
      usersDB.doc(email).update(doc);
    };

    try {
      let foo = null;
      usersDB.doc(email).onSnapshot(async (data) => {
        foo = data.data();
        let links = foo.links;

        if (links[linkIndex] && !links[linkIndex].metrics) {
          links[linkIndex].metrics = [
            {
              clicks: 1,
              date: moment(new Date()).format("YYYY-MM-DD"),
            },
          ];
        } else {
          const idx = links[linkIndex].metrics.length - 1;
          if (
            links[linkIndex].metrics[idx].date ===
            moment(new Date()).format("YYYY-MM-DD")
          ) {
            links[linkIndex].metrics[idx].clicks += 1;
          } else {
            links[linkIndex].metrics.push({
              clicks: 1,
              date: moment(new Date()).format("YYYY-MM-DD"),
            });
          }
        }

        foo.links = links;

        resolveOnce(foo);
      });
    } catch (e) {
      console.error(e);
    }
  });
}

export async function addProfileView(username) {
  let clientInfo = null;
  if(env.env !== 'DEV'){
    clientInfo = await getClientInfo();
  } else {
    clientInfo = require('../fake-metrics.json')
  }

  return new Promise((resolve) => {
    var resolveOnce = (doc) => {
      resolveOnce = () => void resolve(doc);
      usersDB.doc(doc.email).update(doc);
    };

    try {
      let foo = null;

      usersDB.get().then(
        (data) => {
          data.docs.map((profile) => {
            foo = profile.data();
            if (foo.username === username) {
              if (!foo.profileVisits) {
                foo.profileVisits = {
                  data: [clientInfo],
                  count: 1
                }
              } else {
                const data = foo.profileVisits.data;
                data.push(clientInfo)
                foo.profileVisits = {
                  data: data,
                  count: data.length
                }
              }
              return resolveOnce(foo);
            }
            return true;
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } catch (e) {
      console.error(e);
    }
  });
}

async function getClientInfo() {
  try {
    const ipRes = await axios.get("https://api.ipify.org?format=json");

    // Check for information at https://ipregistry.co/docs
    const res = await axios.get(`https://api.ipregistry.co/${ipRes.data.ip}?key=h8lzrls1g3mpck`);
    return res.data
  } catch (e) {
    console.error(e);
  }
}