import styled from "styled-components";

export const LinkzListContent = styled.div`
  background-color: transparent;
  width: 100%;
  /* max-height: calc(100vw - 40px); */
  display: flex;
  flex-direction: column;
  /* padding: 0 20px; */
  padding-top: 20px;
  /* overflow: auto; */
  /* height: 80%; */

  #lzm-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 50%;
    width: 100%;
    /* padding-top: 20px; */
    height: 80%;
    max-height: 80%;
    overflow: auto;
  }

  #links-list-item {
    background-color: #fff;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
  }

  #links-list-item-inner {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0px 0 10px;
    cursor: pointer;
  }

  .justify-bet {
    justify-content: space-between;
  }

  .justify-center {
    justify-content: center;
  }

  @media screen and (min-width: 700px) {
    #links-list-item {
      background-color: #fff;
      height: 60px;
      border-radius: 5px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  #links-empty-list {
    display: flex;
    text-align: center;
    margin: 50px 0;

    span{
      width: 100%;
    }
  }

  .links-list-item-text {
    text-align: center;
    width: 100%;
    user-select: none;
  }

  .dragndrop-link-item {
    /* background: #c3c3c3; */
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    /* left: 10px;
    top: -10px; */
  }

  .link-actions{
    display: flex;
    flex-direction: column;


    .delete-link-item {
      background: rgb(255, 81, 81);
      width: 30px;
      height: 30px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      /* left: 10px;
      top: -10px; */
    }

    .edit-link-item {
      background: rgb(78, 83, 245);;
      width: 30px;
      height: 30px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      /* left: 10px;
      top: -10px; */
      /* margin-top: 5px; */
    }
  }

`;
