import React from "react";
import ReactDOM from "react-dom";
import "rsuite/dist/styles/rsuite-default.css";
import "./css-layout-io.css";
import "./index.css";
import "./rsuitejs.css";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
// import * as environment from "./environments/env";
// import ReactGA from "react-ga4";

// if (environment.env === "PROD") {
  // const TRACKING_ID = "G-3F3TMXKMRT"; // OUR_TRACKING_ID 
  // ReactGA.initialize(TRACKING_ID);
// }

// if (env.env === "PROD") {
//   Sentry.init({
//     dsn:
//       "https://1d7798c95a014a048fc7fba123b0bf92@o510904.ingest.sentry.io/5607229",
//     integrations: [new Integrations.BrowserTracing()],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//   });
// }

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
