import React, { useEffect, useState } from "react";
import { LinkzListContent } from "./styles.js";
import { Icon } from "rsuite";
import { addClick } from "../../services/metrics.service";
import useAnalyticsEventTracker from "../../utils/ga-event-tracker";

export default function LinkzList({
  links,
  manage,
  editLink,
  deleteLink,
  userMail,
}) {
  const [linksList, setLinksList] = useState([]);
  const [email, setEmail] = useState("");
  const [draggableItemIndex, setDraggableItemIndex] = useState(null);

  const gaEventTracker = useAnalyticsEventTracker("Linkz-main");

  function openLink(e, url, index) {
    e.preventDefault();
    e.stopPropagation();

    if (!manage) {
      addClick(email, index);
      gaEventTracker("abriu link");
      window.open(url, "_blank");
    }
  }

  function loadLinks(values) {
    gaEventTracker("carregou links");
    setLinksList(values);
  }

  useEffect(() => {
    loadLinks(links);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links]);

  const dragItem = React.useRef(null);
  const dragOverItem = React.useRef(null);

  // const onDragStart = (event, index) => {
  //   console.log("drag started", index);
  // };

  // const onDragEnter = (event, index) => {
  //   console.log("drag entered", index);
  // };

  const handleSort = () => {
    let list = [...linksList];
    console.log("start", { list });

    const draggedItemContent = list.splice(dragItem.current, 1)[0];

    list.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;
    setLinksList(list);
    console.log("end", { list });
  };

  // const onDragEnd = (event) => {
  //   console.log("drag ended");
  // };

  useEffect(() => {
    setEmail(userMail);
  }, [userMail]);

  return (
    <LinkzListContent>
      {linksList.length > 0 ? (
        <div id="links-list">
          {!!linksList &&
            linksList.map((item, index) => (
              <div
                id="links-list-item"
                key={index}
                onClick={(e) => openLink(e, item.url, index)}
                draggable={manage && draggableItemIndex === index}
                onDragStart={(e) => (dragItem.current = index)}
                onDragEnter={(e) => (dragOverItem.current = index)}
                onDragEnd={handleSort}
              >
                <div
                  id="links-list-item-inner"
                  className={`${!!manage ? "justify-bet" : "justify-center"}`}
                >
                  {!!manage && (
                    <div
                      className="dragndrop-link-item"
                      onClick={(e) => setDraggableItemIndex(index)}
                    >
                      <Icon icon="bolt" style={{ color: "#c3c3c3" }} />
                    </div>
                  )}
                  <div className="links-list-item-text">{item.description}</div>
                  {!!manage && (
                    <div className="link-actions">
                      <div
                        className="edit-link-item"
                        onClick={(e) => editLink(e, index)}
                      >
                        <Icon icon="edit2" inverse={true} />
                      </div>
                      <div
                        className="delete-link-item"
                        onClick={(e) => deleteLink(e, index)}
                      >
                        <Icon icon="trash" inverse={true} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div id="links-empty-list">
          <span>
            Ei! Sua lista está vazia, clique no ícone de "+" para adicionar
          </span>
        </div>
      )}
    </LinkzListContent>
  );
}
