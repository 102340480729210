import { env } from "../environments/env";
import { firebaseAuth } from "../providers/firebase.provider";

export async function logout() {
  try {
    await firebaseAuth.signOut();
    localStorage.removeItem("@AUTH.user.token");
    localStorage.removeItem("@AUTH.user.username");
    localStorage.removeItem("@AUTH.user.email");
    localStorage.removeItem("@AUTH.user.name");
    window.location = env.uiDomain + "/login";
  } catch (e) {
    console.error(e);
  }
}
