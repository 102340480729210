import React, { useEffect, useState } from "react";
import "./styles.js";
import { useHistory } from "react-router-dom";
import { Icon, Button } from "rsuite";
import { ManageLinkzProfileContent, MenuLinkz, Container } from "./styles.js";
import Loading from "../../components/loading/loading.js";
import { logout } from "../../services/auth.service";
import * as eventsProvider from "../../providers/events.provider";
import LinkzFooter from "../../components/linkz-footer";
import personPayment from "../../assets/img/person-payment.svg";
import underConstruction from "../../assets/img/under-construction.svg";
import * as environment from "../../environments/env";
import {
  firebaseFirestore
} from "../../providers/firebase.provider";


export default function DashboardPage() {
  const history = useHistory();
  const usersDB = firebaseFirestore.collection("users");

  const [loading, setLoading] = useState(true);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isSubscriptionActive, setiIsSubscriptionActive] = useState(false);
  const [showRefreshButton, setShowRefreshButton] = useState(false)

  function openCloseMenu() {
    setIsMenuOpened(!isMenuOpened)
  }

  function openLinksManagement() {
    history.push(`/manage-profile`, { preview: true });
  }

  function getDashboardUrl() {
    return `https://lookerstudio.google.com/embed/reporting/4f5a045c-5d25-456c-a433-e68d0e8982de/page/p_1dy7iixvgd?params=%7B"df23":"include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580${localStorage.getItem('@AUTH.user.email')}"%7D`
  }

  function loadSubscription() {
    const userData = JSON.parse(localStorage.getItem('@AUTH.user.userData'))

    setLoading(false);

    if (userData?.subscription?.plan === "PLAN_YEARLY") {
      return setiIsSubscriptionActive(true)
    }

    return setiIsSubscriptionActive(false)
  }

  function reloadUser(username) {
    console.log(username)
    setLoading(true);
    let foo = null;
    usersDB.where("username", "==", username).onSnapshot(async (data) => {
      data.forEach(async (child) => {
        let item = null;
        item = child.data();
        foo = item;
        const bar = !item.links ? [] : item.links;

        localStorage.setItem('@AUTH.user.userData', JSON.stringify({
          name: item.name,
          username: item.username,
          email: item.email,
          photo: item.photo || null,
          links: bar,
          subscription: item.subscription
        }))

        return setLoading(false);
      });

      if (foo) {
        return;
      }
      loadSubscription()
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    });
  }

  useEffect(() => {
    eventsProvider.EventRegister('opened dashboard', '/dashboard')
    loadSubscription()

    setTimeout(() => {
      setShowRefreshButton(true)
    }, 10000)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscriptionActive])

  return (
    <ManageLinkzProfileContent>
      <Loading loading={loading} />

      <div className="container">
        <div className="top">
          <div onClick={() => history.goBack()}>
            <Icon id="menu" icon="back-arrow" />
          </div>

          <div onClick={() => openCloseMenu()}>
            <Icon id="menu" icon="more" />
          </div>
        </div>
        {isMenuOpened && (
          <MenuLinkz>
            <div className="top">
              <div onClick={() => openCloseMenu()}>
                <Icon id="close" icon="close" />
              </div>
            </div>
            <div className="middle">
              <div className="menu-item" onClick={() => openCloseMenu()}><Icon className="menu-icon" icon="dashboard" /> Dashboard</div>
              <div className="menu-item" onClick={() => {
                openLinksManagement();
              }}><Icon className="menu-icon" icon="list" /> Meus links</div>
              <div className="menu-item"
                onClick={() => {
                  history.push(`/${localStorage.getItem('@AUTH.user.username')}`, { preview: true });
                }}><Icon className="menu-icon" icon="id-card" /> Perfil público</div>
              <div className="menu-item" id="logout" onClick={() => logout()}>
                Sair <Icon id="icon-logout" className="menu-icon" icon="sign-out" />
              </div>
            </div>
            <div className="bottom">Versão: 1.0.0</div>
          </MenuLinkz>
        )}
        {/* {!!isSubscriptionActive ? <>
          <div className="page-name">Dashboard</div>
          <div id="mlp-content">
            <iframe
              title="dashboard"
              src={getDashboardUrl()}
              style={{ width: '100%', height: '800px', border: '0' }}
              allowFullScreen
              sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
          </div>
        </> : <div>
          <Container className="container">
            <div id="lzm-not-found-container">
              <div id="not-found-section">
                <img className="img-vector" src={personPayment} alt="Person payment" />
                <div className="content-title">{JSON.parse(localStorage.getItem('@AUTH.user.userData')).name},</div>
                <span>
                  Para visualizar as métricas do seu Linkz, você precisa ser assinante <span style={{padding: '0', margin: '0'}} role="img" aria-label="down">👇👇👇</span> <br /><br /><b>APENAS R$ 19,90/ano!</b><br /><br />
                </span>
                <Button
                  className="primary-btn"
                  block={true}
                  onClick={() => {
                    window.open(environment.env.subscriptionUrl, '_blank')
                  }}
                >
                  Quero assinar agora!
                </Button>

                {!!showRefreshButton && (
                  <Button
                    className="secondary-btn"
                    block={true}
                    onClick={() => {
                      reloadUser(localStorage.getItem('@AUTH.user.username'))
                    }}
                  >
                    <Icon className="icon" icon="refresh" />
                    Já realizei o pagamento, atualizar página
                  </Button>

                )}
              </div>
            </div>
            <LinkzFooter />
          </Container>
        </div>} */}

        <Container className="container">
            <div id="lzm-not-found-container">
        <div id="not-found-section">
          <img className="img-vector" src={underConstruction} alt="Person payment" />
          <span>
            Visualize seus dados aqui:<br /><b>EM BREVE</b>!
          </span>
        </div>
        </div>
        </Container>
      </div>

    </ManageLinkzProfileContent>
  );
}
