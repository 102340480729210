import styled from 'styled-components';

export const Container = styled.div`

width: 100vw;
  /* height: 100vh; */
  height: fit-content;
  padding: 20px;

#lzm-not-found-container{
  width: calc(100vw - 40px);
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}


#not-found-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#lzm-not-found-container img {
  width: 220px;
}

`;