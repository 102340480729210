import styled from "styled-components";

export const ContainerFull = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #ffbc42;
`;

export const LinkzMainContainer = styled.div`
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #ffbc42;

  @media screen and (min-height: 650px) {
    /* height: 100vh; */
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 20px 10vw;
  }

  @media screen and (min-width: 1025px) and (max-width: 1920px) {
    padding: 10px 20vw;
  }

  @media screen and (min-width: 1921px) {
    padding: 30px 30vw;
  }

  padding: 20px;
  .navbar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    #go-back {
      width: fit-content;
      padding: 20px;
      text-decoration: none;
    }
  }

  #section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    /* height: calc(100vh - 77px); */
    width: 100%;
    margin-top: 50px;
  }

  #header {
    /* padding-top: 10vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #header-name {
    font-size: 18px;
    font-weight: bold;
  }

  #header-photo {
    background-color: #e5e5e5;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 15px;
  }

  #header-photo img {
    width: 100%;
    border-radius: 50%;
  }

  #header-username {
    color: #fff;
    font-size: 16px;
    font-weight: 800;
  }

  #list {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* max-height: 50%; */
    width: 100%;
    /* padding-top: 20px; */
    /* height: 50%;
  max-height: 50%; */
    /* overflow: auto; */
  }

  #list-item {
    background-color: #fff;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
  }

  @media screen and (min-width: 700px) {
    #list-item {
      background-color: #fff;
      height: 60px;
      border-radius: 5px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
  }
`;
