import React from "react";
import "./styles.css";
import { env } from "../../environments/env";

export default function LinkzFooter({ disableChat }) {
  return (
    <>
      <div className="linkz-footer">
        <div className="linkz-footer-info">
          {" "}
          Desenvolvido com ❤️ por &nbsp;
          <a
            href={env.uiDomain}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline", color: "inherit" }}
          >
            LINKZ.link
          </a>
        </div>
      </div>
      {/* {disableChat ? (
        ""
      ) : (
        <a
          className="linkz-wpp-support"
          id="linkz-wpp-support"
          href="https://api.whatsapp.com/send?phone=5561998914970&text=Descreva%20em%20poucas%20palavras%20como%20posso%20te%20ajudar%3A"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://cdn-icons-png.flaticon.com/512/733/733585.png"
            alt="wpp-chat"
          />
        </a>
      )} */}
    </>
  );
}
