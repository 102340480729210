export default function showError(value){
    switch (value) {
        case 'auth/user-not-found': return "Usuário não encontrado. Registre-se para utilizar a plataforma";
        case 'auth/invalid-email': return "Formato de e-mail inválido. Verifique se o e-mail está correto";
        case 'auth/wrong-password': return "Senha incorreta. Tente novamente";
        case 'auth/email-already-in-use': return "Já existe uma conta cadastrada com este e-mail. Tente redefinir a senha";
        case 'auth/argument-error': return "Formato de e-mail inválido. Verifique se o e-mail está correto";
        case 'user/user-collection-not-found': return "Ocorreu um erro, entre em contato com o suporte";
        default: return "Erro não identificado. Entre em contato com o suporte";
    }
}