import * as firebaseProvider from './firebase.provider'

export async function ErrorHandler(context, status, message, path) {
    const error = {
        context,
        status,
        message,
        path,
        user: localStorage.getItem("@AUTH.user.email"),
        createdAt: new Date()
    }

    return await firebaseProvider.firestoreInsert('errors', error)
}