import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ForgotPassword from "./pages/forgot-password";
import HomePage from "./pages/homepage";
import LinkzMain from "./pages/linkz-main";
import Login from "./pages/login";

import ManageLinkzProfile from "./pages/manage-linkz-profile";
import ProfileNotFound from "./pages/profile-not-found";
import Register from "./pages/register";
import ThankYou from "./pages/thank-you";
import Dashboard from "./pages/dashboard";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact={true} component={HomePage} />
        <Route path="/login" render={() => <Login />} />
        <Route path="/register" render={() => <Register />} />
        <Route path="/forgot-password" render={() => <ForgotPassword />} />
        <Route path="/thank-you" render={() => <ThankYou />} />
        <Route path="/dashboard" render={() => <Dashboard />} />
        <Route path="/manage-profile" render={() => <ManageLinkzProfile />} />
        {/* <PrivateRoute exact path='/manage-profile' component={ManageLinkzProfile} /> */}
        <Route path="/profile-not-found" render={() => <ProfileNotFound />} />
        <Route
          path="/:username"
          render={(e) => <LinkzMain username={e.match.params.username} />}
        />
      </Switch>
    </Router>
  );
}
