import React, { useEffect } from "react";
import personLost from "../../assets/img/person-lost.svg";
import { Container } from "./styles";
import { Button } from "rsuite";
import LinkzFooter from "../../components/linkz-footer";
import { useHistory } from "react-router-dom";
import useAnalyticsEventTracker from "../../utils/ga-event-tracker";

export default function ProfileNotFound() {
  const history = useHistory();
  const gaEventTracker = useAnalyticsEventTracker("Profile-not-found");

  useEffect(() => {
    gaEventTracker("profile-not-found");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="container">
      <div id="lzm-not-found-container">
        <div id="not-found-section">
          <img className="img-vector" src={personLost} alt="Person lost" />
          <div className="content-title">Opzzz...</div>
          <span>
            O perfil que você está procurando não existe. Que tal aproveitar e
            criar uma conta pra você também?
          </span>
          <Button
            className="primary-btn"
            block={true}
            onClick={() => {
              history.push("/register");
            }}
          >
            Eu quero!
          </Button>
        </div>
      </div>
      <LinkzFooter />
    </Container>
  );
}
