import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";
import {
  firebaseAuth,
  firebaseFirestore,
} from "../../providers/firebase.provider";
import Loading from "../../components/loading/loading";
import { Button, Input } from "rsuite";
import LinkzFooter from "../../components/linkz-footer";
import * as toast from "../../providers/toast.provider";
import validateMail from "../../utils/mail-validator";
import showError from "../../utils/error-handler";
import * as stringFormatter from "../../utils/string.formatter";
import useAnalyticsEventTracker from "../../utils/ga-event-tracker";

export default function Register() {
  const history = useHistory();
  const gaEventTracker = useAnalyticsEventTracker('Register');


  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({
    email: "",
    phone: "",
    username: "",
    password: "",
    passwordConfirm: "",
  });

  async function register() {
    gaEventTracker('finalizar cadastro')
    if (!userData || userData.username === "") {
      return toast.error(
        "Você precisa escolher um nome de usuário para sua conta"
      );
    }
    if (!userData || userData.email === "") {
      return toast.error("Você precisa informar qual é o seu e-mail");
    }
    if (!userData || userData.password === "") {
      return toast.error("O campo senha não pode ser vazio");
    }
    if (userData.password !== userData.passwordConfirm) {
      return toast.error("As senhas que você informou são diferentes");
    }
    if (userData.password.length < 8) {
      return toast.error("Sua senha precisa conter pelo menos 8 caracteres");
    }
    if (!validateMail(userData.email)) {
      return toast.error("O e-mail que você forneceu é inválido");
    }

    const formattedUsername = stringFormatter.generateUsername(userData.username)

    const data = {
      email: userData.email,
      username: formattedUsername,
      phone: userData.phone.replace(/\D/g, ""),
      createdAt: new Date(),
    };

    if(await checkUsernameAlreadyExists(data.username)) {
      return toast.error("Esse nome de usuário já existe. Por favor, selecione outro username");
    }  

    firebaseAuth
      .createUserWithEmailAndPassword(userData.email, userData.password)
      .then(
        (res) => {
          if (res.user && res.user.uid) {
            firebaseFirestore.collection("users").doc(userData.email).set(data);
            localStorage.setItem("@AUTH.user.username", data.username);

            gaEventTracker('finalizou cadastro')

            return history.push({
              pathname: "/thank-you",
              state: { username: data.username },
            });
          }
        },
        (error) => {
          console.error(error.code);
          return toast.error(showError(error.code));
        }
      );
  }

  const checkUsernameAlreadyExists = async (username) => {
   return new Promise((resolve, reject) => {
    firebaseFirestore.collection("users").where('username', '==', username).onSnapshot((res) => {
      if(res.docs.length > 0) {
        return resolve(true)
      }
      return resolve(false)
     });
   })
  }

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <div className="container">
        <div id="register-content">
          <div className="content-title">
            Que prazer em te conhecer! Vamos criar sua conta?
          </div>
          <div className="content-info">
            Por favor, preencha os campos abaixo. É rapidinho!
          </div>
          <div className="form">
            <div className="form-fields p-floating-container">
              <Input
                type="text"
                value={userData.username}
                onChange={(value) =>
                  setUserData({ ...userData, username: value })
                }
                placeholder={"Digite um nome de usuário para sua conta:*"}
              />
              <label>Digite um nome de usuário para sua conta:*</label>
            </div>

            <div className="form-fields p-floating-container">
              <Input
                type="text"
                value={userData.email}
                onChange={(value) =>
                  setUserData({ ...userData, email: value.toLowerCase() })
                }
                placeholder={"Qual é o seu e-mail?*"}
              />
              <label>Qual é o seu e-mail?*</label>
            </div>

            <div className="form-fields p-floating-container">
              <Input
                type="text"
                value={userData.phone}
                maxLength={15}
                onChange={(value) =>
                  setUserData({
                    ...userData,
                    phone: stringFormatter.phoneMask(value),
                  })
                }
                placeholder={"Qual é o seu telefone/whasapp?"}
              />
              <label>Qual é o seu telefone/whasapp?</label>
            </div>

            <div className="form-fields p-floating-container">
              <Input
                type="password"
                value={userData.password}
                onChange={(value) =>
                  setUserData({ ...userData, password: value })
                }
                placeholder={"Escolha uma senha:*"}
              />
              <label>Escolha uma senha:*</label>
            </div>

            <div className="form-fields p-floating-container">
              <Input
                type="password"
                value={userData.passwordConfirm}
                onChange={(value) =>
                  setUserData({ ...userData, passwordConfirm: value })
                }
                placeholder={"Confirme sua senha:*"}
              />
              <label>Confirme sua senha:*</label>
            </div>

            <Button
              id="register-btn"
              className="primary-btn"
              block={true}
              onClick={() => {
                register();
              }}
            >
              Finalizar
            </Button>

            <div id="register-secondary-actions">
              <div
                id="go-back"
                onClick={() => {
                  history.push("/login");
                }}
              >
                Voltar para o login
              </div>
            </div>
          </div>
        </div>
        <LinkzFooter />
      </div>
    </>
  );
}
