import * as firebaseProvider from './firebase.provider';

export async function EventRegister(name, path) {
    const event = {
        name,
        path,
        user: localStorage.getItem("@AUTH.user.email"),
        createdAt: new Date()
    }

    return await firebaseProvider.firestoreInsert('events', event)
}