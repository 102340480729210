import { Alert } from 'rsuite';

const toastDuration = 3000;

export function success(message){
    return Alert.success(message, toastDuration);
}

export function error(message){
    return Alert.error(message, toastDuration);
}