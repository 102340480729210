export const env = {
    env: 'PROD',
    uiDomain: 'https://linkz.link',
    firebaseConfig: {
        apiKey: "AIzaSyCb6hcUFkzTNCTZ0937pMZtj1jJ-h0Fgyk",
        authDomain: "linkz-prod.firebaseapp.com",
        databaseURL: "https://linkz-prod.firebaseio.com",
        projectId: "linkz-prod",
        storageBucket: "linkz-prod.appspot.com",
        messagingSenderId: "1050646451729",
        appId: "1:1050646451729:web:284560801149258bac43b0",
        measurementId: "G-KB32HPHKBZ"
    },
    subscriptionUrl: ""
};