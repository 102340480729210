import firebase from 'firebase';
import { env } from '../environments/env';

export const firebaseImpl = firebase.initializeApp(env.firebaseConfig);
export const firebaseFirestore = firebase.firestore();
export const firebaseStorage = firebase.storage();
export const firebaseAuth = firebase.auth();

export async function firestoreGetAll(collectionRef){
    return (await firebaseFirestore.collection(collectionRef).get()).docs
}

export async function firestoreFind(collectionRef, {queryField, queryOperator, queryValue}){
    return (await firebaseFirestore.collection(collectionRef).where(queryField, queryOperator, queryValue).get()).docs
}

export async function firestoreFindDocument(collectionRef, docReference){
    return (await firebaseFirestore.collection(collectionRef).doc(docReference).get()).data()
}

export async function firestoreInsert(collectionRef, data){
    return await firebaseFirestore.collection(collectionRef).add(data)
}

export async function firestoreUpdate(collectionRef, docReference, data){
    return await firebaseFirestore.collection(collectionRef).doc(docReference).update(data)
}