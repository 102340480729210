import React, { useEffect, useState } from "react";
import { ContainerFull, LinkzMainContainer } from "./styles.js";
import { Avatar, Icon } from "rsuite";
import { firebaseFirestore } from "../../providers/firebase.provider";
import Loading from "../../components/loading/loading";
import LinkzList from "../../components/linkz-list";
import LinkzFooter from "../../components/linkz-footer";
import { useHistory } from "react-router-dom";
import { addProfileView } from "../../services/metrics.service.js";

export default function LinkzMain({ username }) {
  const history = useHistory();
  const usersDB = firebaseFirestore.collection("users");

  const [userData, setUserData] = useState([]);
  const [userLinks, setUserLinks] = useState([]);
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [notFound, setNotFound] = useState(false);

  const [isPreview, setPreview] = useState(false);

  function getUserLinks(username) {
    let foo = null;
    setLoading(true);
    usersDB.where("username", "==", username).onSnapshot(async (data) => {
      data.forEach(async (child) => {
        let item = null;
        item = child.data();
        foo = item;
        setUserData(item);
        setUserLinks(item.links);

        return setLoading(false);
      });

      if (!foo) {
        setLoading(false);
        setNotFound(true);
        return history.push("profile-not-found");
      }
    });
  }

  useEffect(() => {
    getUserLinks(username);

    setPreview(
      (history.location.state && history.location.state.preview) || false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!(history.location.state && history.location.state.preview) || false) {
      addProfileView(history.location.pathname.replace("/", ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerFull>
      <LinkzMainContainer>
        <Loading loading={loading} />
        {!!isPreview && (
          <div className="navbar">
            <div
              id="go-back"
              onClick={() => {
                history.goBack()
              }}
            >
              <Icon icon="left" /> Voltar
            </div>
          </div>
        )}
        <div id="section">
          <div id="header">
            <div id="header-name">{userData && userData.name}</div>
            <div id="header-photo">
              {/* <img src={userData.photo}></img> */}
              <Avatar
                className="img-profile-avatar"
                circle
                src={userData.photo}
              />
            </div>
            <div id="header-username">@{userData && userData.username}</div>
          </div>
          <div id="list">
            {!!userLinks && userLinks.length > 0 && (
              <LinkzList links={userLinks} userMail={userData.email} />
            )}
          </div>
        </div>
        <LinkzFooter disableChat={true} />
      </LinkzMainContainer>
    </ContainerFull>
  );
}
