import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import mailSent from '../../assets/img/mail-sent.svg';
import './styles.css';
import { firebaseAuth } from '../../providers/firebase.provider'
import Loading from '../../components/loading/loading';
import { Button, Input } from 'rsuite';
import LinkzFooter from '../../components/linkz-footer';
import * as toast from '../../providers/toast.provider';
import validateMail from '../../utils/mail-validator';
import showError from '../../utils/error-handler';
import useAnalyticsEventTracker from '../../utils/ga-event-tracker';

export default function ForgotPassword() {

  const history = useHistory();
  const gaEventTracker = useAnalyticsEventTracker("Forgot-password");

  const [loading, setLoading] = useState(true);
  const [sentSuccess, setSentSuccess] = useState(false);
  let[counter, setCounter] = useState(5);

  const [userData, setUserData] = useState({
    email: '',
  });

  async function send() {
    if (!userData || userData.email === '') { return toast.error('Você precisa informar qual é o seu e-mail'); }
    if (!validateMail(userData.email)) { return toast.error('O e-mail que você forneceu é inválido'); }

    firebaseAuth.sendPasswordResetEmail(userData.email).then(
      (res) => {
        setSentSuccess(true);
        startCounter();
        gaEventTracker('solicitou nova senha')
      },
      (error) => { console.error(error.code); return toast.error(showError(error.code)); }
    );
  }

  function startCounter(){
    setInterval(() => {
      if(counter === 0){
        history.push('/login')
      }
      setCounter(counter--);
    }, 1000);
  }

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <div className='container'>
        {sentSuccess ?
          <div id="tks-container">
            <div id="tks-section">
              <img className='img-vector' src={mailSent} alt="Mail sent"/>
              <div className='content-title'>Protinho!</div>
              <span>Verifique seu e-mail. Te enviamos um link para recuperar a senha!</span>

              <div id='thanks-redirect'>
                <div>Você será redirecionado em</div>
                <div id='thanks-counter'>{counter}...</div>
              </div>
            </div>
          </div> :

          <div id="forgot-content">
            <div className="content-title">Vamos recuperar sua senha!</div>
            <div className='content-info'>Para recuperar sua senha, informe no campo abaixo seu endereço de e-mail:</div>
            <div className="form">

              <div className="form-fields p-floating-container">
                <Input
                  type="text"
                  value={userData.email}
                  onChange={value => setUserData({ ...userData, email: value.toLowerCase() })}
                  placeholder={"Qual é o seu e-mail?"} />
                <label>Qual é o seu e-mail?</label>
              </div>

              <Button
                id='forgot-btn'
                className='primary-btn'
                block={true}
                onClick={() => { send() }}>Enviar</Button>

              <div id='forgot-secondary-actions'>
                <div
                  id='go-back'
                  onClick={() => { history.push('/login') }}>Voltar para o login</div>
              </div>
            </div>
          </div>
        }
        <LinkzFooter />
      </div>
    </>
  );
}
