import React, { useEffect, useState } from "react";
import personLove from "../../assets/img/person-love.svg";
import "./styles.css";

import LinkzFooter from "../../components/linkz-footer";
import { useHistory } from "react-router-dom";
import useAnalyticsEventTracker from "../../utils/ga-event-tracker";

export default function ThankYou({ username }) {
  const history = useHistory();
  const gaEventTracker = useAnalyticsEventTracker("Thank-you");


  let [counter, setCounter] = useState(5);

  useEffect(() => {
    gaEventTracker('completou cadastro')
    setInterval(() => {
      if (counter === 0) {
        history.push("/manage-profile");
      }
      setCounter(counter--);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div id="tks-container">
        <div id="tks-section">
          <img className="img-vector" src={personLove} alt="Person love" />
          <div className="content-title">Protinho!</div>
          <span>
            Muito obrigado por escolher a nossa plataforma. Estamos trabalhando
            para oferecer sempre o melhor serviço a você!
          </span>

          <div id="thanks-redirect">
            <div>Você será redirecionado em</div>
            <div id="thanks-counter">{counter}...</div>
          </div>
        </div>
      </div>
      <LinkzFooter />
    </div>
  );
}
