export function phoneMask(phone) {
  let value = typeof phone === "number" ? phone.toString() : phone;

  value = value.replace(/[^\d]/g, "");

  return value.replace(/(\d{2})(\d{4,5})(\d{4})/g, "($1) $2-$3");
}

export function removeAccents(value) {
  const accents =
    "ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
  const accentsOut =
    "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";

  value = value.split("");

  const strLen = value.length;
  var i, x;

  for (i = 0; i < strLen; i++) {
    if ((x = accents.indexOf(value[i])) !== -1) {
      value[i] = accentsOut[x];
    }
  }
  return value.join("");
}

export function generateUsername(value) {
  value = removeAccents(value).trim();
  value = value.replace(/[^a-z0-9- ]/gi, "");
  value = value.replace(/\s/g, "-");
  console.log({ value });
  return value;
}
