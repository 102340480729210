import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo-black.svg";
import "./styles.css";

import {
  firebaseAuth,
  firebaseFirestore,
} from "../../providers/firebase.provider";
import Loading from "../../components/loading/loading";
import { Button, Icon, Input } from "rsuite";
import LinkzFooter from "../../components/linkz-footer";
import { useHistory } from "react-router-dom";

import * as toast from "../../providers/toast.provider";
import showError from "../../utils/error-handler";
import useAnalyticsEventTracker from "../../utils/ga-event-tracker";
import * as eventsProvider from "../../providers/events.provider";
import * as errorProvider from "../../providers/error.provider"

export default function Login() {
  const history = useHistory();
  const gaEventTracker = useAnalyticsEventTracker("Login");

  const [loading, setLoading] = useState(true);
  const [hidePassword, setHidePassword] = useState(true);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  async function login() {
    eventsProvider.EventRegister('click to login', '/login')

    if (!form.email || form.email === "") {
      return toast.error("Você precisa informar qual é o seu e-mail");
    }
    if (!form.password || form.password === "") {
      return toast.error("Você precisa informar qual é a sua senha");
    }

    firebaseAuth.signInWithEmailAndPassword(form.email, form.password).then(
      (data) => {
        firebaseFirestore
          .collection("users")
          .doc(form.email)
          .onSnapshot((value) => {
            const val = value.data();
            if (val) {
              localStorage.setItem("@AUTH.user.username", val.username);
              localStorage.setItem("@AUTH.user.email", form.email);
              localStorage.setItem("@AUTH.user.name", val.name);

              eventsProvider.EventRegister('login success', '/login')
              history.push({
                pathname: "/manage-profile",
              });
            }
          });
      },
      (error) => {
        errorProvider.ErrorHandler('login', error.code, error, '/login')
        return toast.error(showError(error.code));
      }
    );
  }

  useEffect(() => {
    if (localStorage.getItem("@AUTH.user.username")) {
      history.push({
        pathname: "/manage-profile",
      });
    }

    eventsProvider.EventRegister('view login page', '/login')

    setLoading(false);
  }, [history]);

  return (
    <>
      <Loading loading={loading} />
      <div className="container">
        <div id="login-content">
          <div id="logo-login">
            <img className="img-logo" src={logo} alt="Linkz Logo" />
          </div>
          <div className="form">
            <div className="form-fields p-floating-container">
              <Input
                type="text"
                value={form.email}
                onChange={(value) =>
                  setForm({ ...form, email: value.toLowerCase() })
                }
                placeholder={"Qual é o seu e-mail?"}
              />
              <label>Qual é o seu e-mail?</label>
            </div>

            <div className="form-fields p-floating-container justify-center">
              <Input
                type={hidePassword ? "password" : "text"}
                value={form.password}
                onChange={(value) => setForm({ ...form, password: value })}
                placeholder={"Qual é a sua senha?"}
              />
              <Icon
                id="mlp-show-hide-password"
                icon={hidePassword ? "eye-slash" : "eye"}
                onClick={(e) => setHidePassword(!hidePassword)}
              />
              <label>Qual é a sua senha?</label>
            </div>

            <Button
              id="login-btn"
              className="primary-btn"
              block={true}
              onClick={() => {
                login();
              }}
            >
              Entrar
            </Button>

            <div id="login-secondary-actions">
              <div
                id="forgot"
                onClick={() => {
                  gaEventTracker("esqueci a senha");
                  history.push("/forgot-password");
                }}
              >
                Esqueceu a senha?
              </div>
              <div
                id="register"
                onClick={() => {
                  gaEventTracker("registrar-se");
                  history.push("/register");
                }}
              >
                Não se cadastrou ainda? <b>Clique aqui!</b>
              </div>
            </div>
          </div>
        </div>
        <LinkzFooter />
      </div>
    </>
  );
}
