import React from "react";
import tortilla from "../../assets/img/tortilla.svg";
import logo from "../../assets/img/logo-black.svg";
import "./styles.css";
import { useHistory } from "react-router-dom";
import { Button } from "rsuite";
import useAnalyticsEventTracker from "../../utils/ga-event-tracker";

export default function HomePage() {
  const history = useHistory();
  const gaEventTracker = useAnalyticsEventTracker("Forgot-password");

  return (
    <div id="site-container">
      <div id="tortilla-content">
        <img src={tortilla} alt="Linkz Tortilla"/>
      </div>

      <div id="site-content">
        <div id="site-logo-content">
          <img src={logo} alt="Linkz Logo"/>
        </div>

        <div id="site-header">Múltiplos links na sua bio do instagram</div>
        <div id="site-info">Direcione o tráfego para onde você quiser</div>

        <div id="home-buttons">

        <Button
          id="site-btn"
          className="primary-btn"
          // block={true}
          onClick={() => {
            gaEventTracker('clicou cadastro')
            history.push("/register");
          }}
        >
          Criar conta
        </Button>
        <Button
          id="site-btn"
          className="ghost-btn"
          // block={true}
          onClick={() => {
            gaEventTracker('clicou login')
            history.push("/login");
          }}
        >
          Entrar
        </Button>
        </div>
      </div>
    </div>
  );
}
